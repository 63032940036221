import Swiper, { Navigation, Pagination } from "swiper";

const swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination],

  slidesPerView: "auto",
  centeredSlides: true,
  centeredSlidesBounds: true,
  slidesOffsetAfter: 32,

  breakpoints: {
    767: {
      centeredSlides: false,
      centeredSlidesBounds: false,
      slidesOffsetAfter: 0,
    },
  },

  pagination: {
    el: ".swiper-pagination",
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

swiper.slideTo(2);
